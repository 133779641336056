<template>
  <div>
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="代理商/门店名称：">
           <el-input
            placeholder="请输入代理商/门店名称"
            v-model="searchForm.unitName"
            clearable
          >
          </el-input>
        </el-form-item>
         <el-form-item label="组织编号：">
          <el-input
            @keyup.native = "searchForm.unitNo = searchForm.unitNo.replace(/[^\d]/g,'')"
            placeholder="请输入组织编号"
            v-model="searchForm.unitNo"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="关联账户：">
          <el-input
            placeholder="请输入关联账户"
            v-model="searchForm.phone"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="角色：">
          <el-select ref="searchFormRole" v-model="searchForm.role" clearable>
            <el-option
              v-for="opt in roleObj"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" class="bkpurpleDeep bdpurpleDeep">查询</el-button>
          <el-button class="bkcyan bdcyan" type="primary" @click="goAddAgent" icon="el-icon-success">新增代理商</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="unitNo" label="组织编号" width="200">
        </el-table-column>
        <el-table-column prop="name" min-width="150" label="名称">
        </el-table-column>
        <el-table-column prop="description" min-width="200" label="地区">
        </el-table-column>
        <el-table-column prop="role" label="角色">
          <template slot-scope="{ row }">
            {{ $changeObj(ROLE_OPTIONS)[row.role] }}
          </template>
        </el-table-column>
        <el-table-column prop="agentName" label="所属代理商" min-width="150">
        </el-table-column>
        <el-table-column prop="relatePhone" label="关联账号" width="150">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="200">
        </el-table-column>
        <el-table-column prop="city" fixed="right" width="240" label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" class="bkpurpleDeep bdpurpleDeep" @click="goAddShop(row)" v-if="row.role == 1">新建门店</el-button>
            <el-button type="primary" size="mini" class="bkpurpleDeep bdpurpleDeep" @click="goEdit(row)">编辑</el-button>
            <!-- <el-button type="primary" size="mini" class="bkpurpleDeep bdpurpleDeep" @click="openSet(row)"  v-if="row.role == 1">配置</el-button> -->
            <el-button type="primary" size="mini" class="bkpurpleDeep bdpurpleDeep" @click="delAgent(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="配置项" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="结算周期">
          <el-select v-model="settlementPeriod" clearable>
            <el-option
              v-for="opt in SETTLE"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveSet">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { SETTLE ,ROLE_OPTIONS} from "@/js/constant";
import { getAgentList, updateSet, delAgent } from "@/api/agent";

export default {
  data() {

    return {
      roleObj:[
        {
          label: "代理商",
          value: 1
        },
        {
          label: "门店",
          value: 2
        }
      ],
      ROLE_OPTIONS,
      /** 查询 */
      searchForm: {
        unitNo: "",
        phone: "",
        role: ""
      },
      select: "2",
      SETTLE,
      beginIndex: 1,
      pageSize: 20,
      total: 0,
      list: [],
      /** 弹窗 */
      dialogVisible: false,
      // 结算周期
      settlementPeriod: "",
      /** 更改的数据 */
      row: {}
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormRole.blur()
        _this.onSearch()
      }
    };
    this.onSearch();
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addAgent' || from.path === '/leaseholdMgmt/addShop') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.getAgentList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getAgentList();
    },
    /*获取列表 */
    async getAgentList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        pageSize: this.pageSize,
        beginIndex: page
      };
      const res = await getAgentList(params);
      this.list = res.ldata;
      this.total = res.mdata.total;
    },
    goAddAgent() {
      this.$store.dispatch('login/checkHandle',{checkURI:'/managementPlatform/operation/createOrUpdateUnit', type: 0} ).then((flag) => {
        if(flag) {
          this.$router.push("/leaseholdMgmt/addAgent");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push("/leaseholdMgmt/addAgent");
      })
    },
    goAddShop(row) {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/operation/createOrUpdateUnit' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addShop",
            query: {
              parentUnitNo: row.unitNo,
              type: "add"
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addShop",
          query: {
            parentUnitNo: row.unitNo,
            type: "add"
          }
        });
      })

    },
    /** 编辑 */
    goEdit(row) {
      const query = {
        unitNo: row.unitNo,
        type: "edit",
        description: row.description,
        location: row.location,
        relatePhone: row.relatePhone,
        name: row.name
      };
      this.$store.dispatch('login/checkHandle', '/managementPlatform/operation/createOrUpdateUnit' ).then((flag) => {
        if(flag) {
          if (row.role == 1) {
            this.$router.push({
              path: "/leaseholdMgmt/addAgent",
              query:{
                type:'edit',
                unitNo:row.unitNo,
                }
            });
          } else {
            // 门店
            this.$router.push({
              path: "/leaseholdMgmt/addShop",
              query:{
                type:'edit',
                unitNo:row.unitNo,
                }
            });
          }
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
       if (row.role == 1) {
          this.$router.push({
            path: "/leaseholdMgmt/addAgent",
            query:{
              type:'edit',
              unitNo:row.unitNo,
              }
          });
        } else {
          // 门店
          this.$router.push({
            path: "/leaseholdMgmt/addShop",
            query:{
              type:'edit',
              unitNo:row.unitNo,
              }
          });
        }
      })
    },
    openSet(row) {
      this.row = row;
      this.dialogVisible = true;
    },
    // 保存配置项
    async saveSet() {
      if (!this.settlementPeriod) {
        this.$message.error("请选择结算周期");
        return;
      }
      const params = {
        unitNo: this.row.unitNo,
        settlementPeriod: this.settlementPeriod
      };
      const res = await updateSet(params);
      this.dialogVisible = false;
      this.onSearch();
    },
    /** 删除 */
    delAgent(row) {
      this.$confirm("是否确认删除此代理商/门店?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            unitMemberNo: row.unitMemberNo
          };
          await delAgent(params);
          this.$message.success("删除成功");
          this.onSearch();
        })
        .catch(() => {});
    }
  },
  components: {}
};
</script>

<style scoped></style>
