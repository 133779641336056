<template>
  <el-form
    class="form"
    ref="form"
    :model="form"
    :rules="rules"
    label-width="150px"
  >
    <el-form-item label="门店名称：" prop="name">
      <el-input
        v-model="form.name"
        style="width: 300px"
        placeholder="请输入门店名称"
        :maxlength="25"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店地区：" prop="description">
      <Area v-model="form.description" style="width: 300px" />
    </el-form-item>
    <el-form-item label="门店地址：" prop="location">
      <el-input
        v-model="form.location"
        style="width: 300px"
        placeholder="请输入门店地址"
        :maxlength="150"
      ></el-input>
    </el-form-item>
    <el-form-item label="关联账号：" prop="relatePhone">
      <el-input
        v-model="form.relatePhone"
        style="width: 300px"
        placeholder="请输入关联账号"
        :maxlength="11"
      ></el-input>
    </el-form-item>
    <el-form-item label="支付宝账号类型" prop="accountType">
      <el-select
        v-model="form.accountType"
        clearable
        placeholder="请选择支付宝账户类型"
        style="width: 300px"
        @change="handleChangeType"
      >
        <el-option
          v-for="opt in ALIPAY_STATUS"
          :key="opt.value"
          :value="opt.value"
          :label="opt.label"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="资金账号" prop="accountNo">
      <el-input
        v-model="form.accountNo"
        style="width: 300px"
        clearable
        placeholder="请输入资金账号"
        :maxlength="25"
      ></el-input>
    </el-form-item>
    <el-form-item label="公司名称" prop="accountName">
      <el-input
        v-model="form.accountName"
        style="width: 300px"
        clearable
        placeholder="请输入公司名称"
        :maxlength="25"
      ></el-input>
    </el-form-item>
    <el-form-item
      prop="isUseHigherLevelCommodity"
      v-if="$route.query.type != 'edit'"
    >
      <el-checkbox v-model="form.isUseHigherLevelCommodity"
        >沿用上级商品</el-checkbox
      >
    </el-form-item>
    <el-form-item class="mt20">
      <el-button type="primary" @click="submitForm" :loading="disaled"
        >确认</el-button
      >
      <el-button type="info" @click="goBack">返回</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { addAgent } from "@/api/agent";
import Area from "@/components/Area";
import Reg  from '@/js/reg'
import { ALIPAY_STATUS } from "@/js/constant";
import { queryHaiLiUnitDetail } from "@/api/common";
import { queryUnitChannlAccount } from "@/api/fundingParty";
export default {
  data() {
    const validatPhone = (rule, value, callback) => {
      if (!value) {
        return  callback(new Error('请输入正确手机号'));
      }
      if (!Reg.isPoneAvailable(value)) {
        return  callback(new Error('请输入正确手机号'));
      }
       callback();
    }
    return {
      form: {
        name: "",
        description: '',
        location: "",
        relatePhone: "",
        isUseHigherLevelCommodity: false,
        accountNo: "",
        accountType: "",
        accountName: "",
      },
      rules: {
        name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
        relatePhone: [
          { required: true, message: "请输入正确手机号", trigger: "blur" },
          { validator: validatPhone, trigger: 'blur' }
        ]
      },
      disaled: false,
      ALIPAY_STATUS
    };
  },
  mounted() {
    if (this.$route.query.type == "edit") {
        this.queryHaiLiUnitDetail()
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.disaled = true;
          const params = {
            ...this.form,
            isUseHigherLevelCommodity: Number(
              this.form.isUseHigherLevelCommodity
            ),
            role: 2
          };
          if (this.$route.query.type == "add") {
            params.parentUnitNo = this.$route.query.parentUnitNo;
          }
          if (this.$route.query.type == "edit") {
            params.unitNo = this.$route.query.unitNo;
            delete params.isUseHigherLevelCommodity;
          }
          if (typeof this.form.description != 'string') {
            params.description = this.form.description.join(',');
          }
          try {
            const res = await addAgent(params);
            this.disaled = false;
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.push("/leaseholdMgmt/agent");
            }, 1000);
          } catch (error) {
            this.disaled = false;
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.push("/leaseholdMgmt/agent");
    },
      /** 获取账户信息 */
    async queryHaiLiUnitDetail() {
      const params = {
        unitNo: this.$route.query.unitNo
      };
      const res = await queryHaiLiUnitDetail(params);
      this.form = res.mdata.detail;
        const {
        description,
        isUseHigherLevelCommodity
      } = this.$route.query;
      if (description) {
        this.form.description = description.split(",");
      }
      this.form.isUseHigherLevelCommodity =  +isUseHigherLevelCommodity == 1
    },
     //根据支付宝账户类型获取信息
    async queryUnitChannlAccount() {
      const params = {
        unitNo: this.$route.query.unitNo,
        accountType: this.form.accountType
      };
      const res = await queryUnitChannlAccount(params);
      this.$set(this.form, "accountName", res.mdata.accountName);
      this.$set(this.form, "accountNo", res.mdata.accountNo);
    },
    handleChangeType(value) {
      this.form.accountType = value;
      if (this.$route.query.type == "edit" && value) {
        this.queryUnitChannlAccount();
      }
    }
  },
  components: {
    Area
  }
};
</script>

<style scoped></style>
